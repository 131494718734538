var vm = require('./common/VueModel.js');
var $ = require('jquery');
var stickyFooter = require('./common/stickyFooter');
var offCanvasMenu = require('./common/offCanvasMenu');
var loginPopup = require('./common/loginPopup');
var commonPopups = require('./common/commonPopups');
var headerNavDropdowns = require('./common/headerNavDropdowns');
var datatables = require('./datatables.js');
var magnificPopup = require('magnificPopup');
var cookiePolicy = require('./common/cookiePolicy');
var Messages = require("./messages.js");

$(document).ready(function(){

    //////////////// On Ready ////////////////

    stickyFooter();
    offCanvasMenu();
    loginPopup();
    commonPopups();
    headerNavDropdowns();
    datatables.setupDataTables();
    cookiePolicy();

    var typingTimer;
    var doneTypingInterval = 750;
    var ajaxCall;
    var idInput = $('#js-company-id');
    var selectedNameSelect = $('#js-selected-name');
    var loadingIcon = $('#js-loading-selected');
    var siteId = $('#js-site-id');
    var submitButton = $('#js-removal-submit-button');

    function doneTyping() {
        ajaxCall = $.ajax({
            type: 'GET',
            url: '/removalIdSearch/' + idInput.val(),
            success: getIdSuccess,
            error: getIdFail
        });
    }

    function getIdSuccess(data) {

        if (typeof data.results !== 'undefined' && data.results.length) {
            var options = '';
            $.each(data.results, function(i, el) {
                options += '<option data-site-id="' + getSiteId(el.type) + '">' + el.name + ' (' + getType(el.type) + ')' + '</option>'
            });

            siteId.val(getSiteId(data.results[0].type));
            selectedNameSelect.html(options);
            loadingIcon.addClass('hide');
            enableSubmitButton();
        } else {
            selectedNameSelect.html("<option disabled='true'>" + Messages.get('static.noneFound') + "</option>");
            loadingIcon.addClass('hide');
        }
    }

    function getIdFail() {
        loadingIcon.addClass('hide');
        selectedNameSelect.html("<option disabled='true'>" + Messages.get('static.sorryTryAgain') + "</option>");
    }

    function enableSubmitButton() {
        submitButton.removeClass('disabled');
        submitButton.attr('disabled', false);
    }

    function disableSubmitButton() {
        submitButton.addClass('disabled');
        submitButton.attr('disabled', true);
    }

    function getType(type) {
        switch (type) {
            case 'company-uk':
                return Messages.get('static.type.ukLim');
            case 'company-nltd':
                return Messages.get('static.type.ukNonLim');
            case 'director-uk':
                return Messages.get('static.type.director');
        }
    }

    function getSiteId(type) {
        switch (type) {
            case 'company-uk':
                return 1;
            case 'company-nltd':
                return 3;
            case 'director-uk':
                return 2;
        }
    }

    //on keyup or paste, start the countdown
    idInput.bind('keyup paste', function(e){

        if (ajaxCall) {ajaxCall.abort()};
        disableSubmitButton();
        selectedNameSelect.html('');
        loadingIcon.removeClass('hide');

        clearTimeout(typingTimer);
        if (idInput.val) {
            typingTimer = setTimeout(doneTyping, doneTypingInterval);
        }
    });

    selectedNameSelect.on('change', function() {
        siteId.val($('option:selected', this).data('site-id'));
    });

    //////// Popups ////////

    $('.find-your-id-popup, .check-no-index-popup').magnificPopup({
        type:'inline',
        closeBtnInside: true,
        midClick: true
    });

    //////////////// On Resize ////////////////

    $(window).resize(function() {
        stickyFooter();
    });
});


